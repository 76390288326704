/* You can add global styles to this file, and also import other style files */
body{

    font-size: 14px !important;
    background: #f4f4f4 !important;
    font-display: swap;

}

div, a{

    outline: none!important;
    text-decoration: none!important;

}

#scrollUp {
    width: 30px;
    height: 30px;
    border-radius: 8px;
}

/* html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */
